import Swiper from "swiper";
import { Navigation, A11y } from "swiper";
import { initNeosEventItemCreated } from "../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../Assets/Scripts/neosEvents";

export function eventSwiper() {
    document.querySelectorAll(".event--swiper").forEach((slider) => {
        const isNeosBackend = document.body.classList.contains('neos-backend')

        const swiper = new Swiper(slider, {
            modules: [Navigation, A11y],
            allowTouchMove: !isNeosBackend,
            slidesPerView: "auto",
            spaceBetween: 0,
            watchOverflow: true,
            resizeObserver: true,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            navigation: {
                nextEl: ".swiper-navigation-next",
                prevEl: ".swiper-navigation-prev",
            },
        });

        var outdatedItems = slider.querySelectorAll(".outdated");
        for (const outdatedItem of outdatedItems) {
            var nextElement = outdatedItem.nextElementSibling;

            if (!nextElement.classList.contains('outdated')) {
                const getIndex = (element) => parseInt(
                    (element.getAttribute("aria-label") ?? "").split(" / ")[0],10
                );
                swiper.slideTo(getIndex(nextElement) - 1);
            }
        }

        initNeosEventItemCreated(swiper, slider);
        initNeosEventItemSelected(swiper, slider);
    });
}
eventSwiper()
